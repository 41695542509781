const whitelistAddresses = 
["0x238cE2985549A6e6b44812eA2A7B4cd3aBcB3301",
"0xe612cbd25112e09a1702807c1dE8731536590502",
"0x544d7Df5043711dAC2B5d2Da93EAB60256A2a21A",
"0xC629E268C75c9f248a5448907066Fe95092e6505",
"0xb7190C6eF9E1CceA0320A554132b33Dd065F2d90",
"0x2aA42662d673c880082755CE4D60235446bb6AfC",
"0x48Bb5e0cd35D442682abFEf9F1A0169e61514f90",
"0xe9b70a8997b155354c7627dae8af38114f723f34",
"0xA785C264293785e574F00FcdC88353a3bB690bce",
"0x729513301718974332E29f8Df16E6bcb2801Dc23",
"0x747A9CabAFB32DFf27348d2e5a6602b7D939A56d",
"0x381d5D224c78094e06FAFf2bA82ecc7ed05EFb02",
"0x9047d314ff14B89d9bbD785ddEfEBA691ed0bba3",
"0x3f0C7d4Be781CeCfAE4ec34f4BfA3cdE519bcf04",
"0x4FEe06Eb54d0c48CF6760730499F37C1875b07dB",
"0xfE5DCf645E4BA46C3264EF9070eDbFa23D50E91C",
"0xceC8b2c52de99Ab5Ed1021ba8B90154EdeeD0e27",
"0x0586816667CC1E40c1c1923eCfE9484e417E2a43",
"0xd96C5355681AF559f358DF8E5C0Cf0Ede2FBf206",
"0xE8517418753eAe2c158ae413255f7DfedF18B2E4",
"0x747FFd149b4b047742BB37B196dC0AE9bf9c5067",
"0x4A4c47FA5f4956E8829Ec0950cF66AF1de906eA8",
"0xE077E4c8a0401ceC80E31af61e2FF29156c8e8A5",
"0x7A9e6c850F13C55bDD0f9F74ea60e09B3A35569F",
"0x778B512dC6b905C205d57AAd18323F507608fB50",
"0x0ee58839510385199452BDD4Bc90d3C712d50BFd",
"0xB5637c0ae93CF91499fd14CDd2A6C4a2d23da55C",
"0x0522B29D93c5dCef964917952973bc037f4EA0F8",
"0xBcb6Dc98b6d431509D6Cc8FE952163a849B92DA6",
"0xcD8d2eA99163e60574a9F89fa006fE7E6403bcD6",
"0xF7D54092d351D4df50727FFC0E724fe608528566",
"0xC9E4aA8f2476F48eC24B0cECc1E2A2A16986e025",
"0xc1E58b75c7117F9dbECdB9CE07165eB5A6B25B3A",
"0x92b9E9aC7d91D545A8638448FfF80F0Fbf145249",
"0x0287596DAfc77Ae743b809507Ec8c27C1E77e64A",
"0x16EAa76388Ae752123211e0Eae3cB5CAB75EB938",
"0xfA1F1e64Ac1db8802a8d7D643417cc7De8951A94",
"0xD8B642dfE77672E2dC7aBe95363E860B4B0eBb4A",
"0x956EcE5EDAf5136503511C74D5eAA935BCc26879",
"0xE59f4a315Aa1d99bDD32D999BD265aEE215c6Cf6",
"0x95eeed6c276e103ee1ee90fff3a0DFcee49C4529",
"0x00869FC6e6c9f578667D2f864a78b30ccDD67e5F",
"0xad08048F5AD082994B296697481e4e5dbaf2454E",
"0xD353DE9bDC5dA42C6Ab025d90075Ab6209FB1042",
"0x430D58cC66648d7b8D65a5Fd75907eB8196e1e48",
"0x05659416DDc88b3f733b3209d37C0A912E77e2B9",
"0xEB642d25FE4b0709186904f20608d0e9f23f2c6E",
"0xdBdef9Db0a76590b1F2CeA3c66707695bcE54BE0",
"0x7Af367DB12D1aa5f08A0751bB20E18443b41bE1A",
"0xFd9Ae799d5b82eC2A21387fA466117a49a28603f",
"0xC9a226393fDeCFa0452B2E83dFd3501aA03E79e2",
"0x619186F6a1774607DE5614BEcF2872f159f70a3D",
"0x2E7fEc87B57B72280804d9C4c2f60FF5b2344B85",
"0xf84Bfca4A04959bB829b38Fe41A92B2933845a63",
"0x362B1dF85A7fC9dc7f994A069F8C49824Eea5c66",
"0x83ECEA1CE1a21D85E096030a8a945CB3453c727f",
"0xBd9F1259ab34C33DB4531798F542c11fB9B4E979",
"0x18B7504585EAA79AbeE83CC66377955E5A32d822",
"0xB2C17294989bAa1b421908407b3BD21c6C4cAc2E",
"0xC445278e3Db529824F570287294f20b76D8960a7",
"0xE66310628699b5854cDa4136B6dfb4B5A0D147eD",
"0x1DAb72FdB541c12276a3575a1Bc073d4E4fE7D65",
"0x680180Da3c5e8c7B1e527E993939970C0CE0FC3e",
"0xB2A2F6C02D157dA553c3Cf511260f443634bdc06",
"0x816E4566c01d9499789d54cEE384567103b25Fe5",
"0xf3e917a4307de129b30c53991ad671fe8a072721",
"0xa87E3fB929e9B56C2D0312c79fab2B9dFD411611",
"0x5ee151F79c933A41387552d389DF941B50702606",
"0xA7Ca683eDd344bb079fb6464D07f47Eaebebc01f",
"0x77edaa6DCc0b488E06d849be36092EFCfe4050DB",
"0xea9A1054a7F09897fD74F0522349C9c998C1F985",
"0x0D887B29234C7872Bfa4c10eaF55bc59D236fb06",
"0x9a854d260ac0fedf74fabbd77dc4051cb4b0170c",
"0xc60E9b772fC1f9Ac271bacbecF71Db141fE07F15",
"0x1eCf8bC182eCE42C9f65FCC3FCfc67f8ca062CaE",
"0x10b4ee99F35df1fDe98226eF7438Fec12909eF96",
"0x5ef7b011F35ABCcA6582D1f01bC8eaBEc9cDDbde",
"0xb1aFdB473C946d42E8d24C84E257D4403C72f115",
"0xa5d85515a7c1a0f8f8e5C88f685247c93CD2bdbE",
"0x2339EdA6bb5BFa477873Fa937796bb6a696A8bEf",
"0xF57683787b8Bb1e86c6d0ed1C86928d5156142f9",
"0x9f9d0d288d646a0ac50a48a3e6eb000a5a785e82",
"0x335ed86c96073e1445E767a79F64F1d6A4fDAda3",
"0x1d5ECb54745e44Bc3b11Dd704388b3De77910DB1",
"0xeb53865b549ab4A523f8651333451ef16d1B648a",
"0x72d42C9cf487fF710219BeDC6225C432c5eCB264",
"0xE3c2A6279E6f6De9217Ef09CA33eFb18e0d8637A",
"0x61A214C5D469fF9575818201f9a5B54Db8228010",
"0x398959F6fD30269bAdbbC5acEe88dDdd7C86E6E5",
"0xa755B6cAF81e584d3B1B8623e647422486184cA6",
"0xAd880171543b3283Da9892D3F64f6Fe9dc4f7A6c",
"0x612B253110bA3C1BBe6bAA33465eCc961FE6D75d",
"0x7AEFa5d4a423128ff19E65CEfc55D2FEFe59C9d9",
"0x5404A4D869b31e1ce899B02C54A0C3556A21e4bD",
"0xA986a1F8d4Fd4ab5c2d7F458449Ad9B57BEC1fBf",
"0xcc3554858da0c9Bb8ef7f4f68376ce51Df32c4Be",
"0xAf51BcC6efA5ACBa2c9B505F18A492E820ba5f58",
"0x92295eEC081be3C7EfB23bF40Aaabbb230E2DC77",
"0xbD7a8fd9b931F277F45De03afDaB79C84A00E224",
"0x13E0eeD7663957b18D28b08f90835C7fd5bBA053",
"0x642B45ac535097F65ED66c0875e874FBb8126446",
"0x1030E91ADB202a2815363a21737a667d69d00C60",
"0x4B42391Baa84D0D412c2c89ba78d05304a7D679b",
"0x26fA33dFe4D07E20B5033b116063650F89894089",
"0xe4e3341864eA33e99168De61EDB64781b432F06a",
"0x3347B423FF3DB1532cE92B4e03DE7927902ba334",
"0x23C108c47b5abAcADb5f8880072db57fA60e0675",
"0xA83744Ebb1321e21dF821759EF43F80a9df279aA",
"0x19655b64BC180742F8CC62129D2B29A4b76897De",
"0xDCc75A0F194b9EB51e1091954d0804eAe8cAc233",
"0x7204487FD0815dcDD29B92E3Bd84b09e9CC8Ab34",
"0xA68D6D3DF01D7D000cd2E8D746c3867B7e50f0aA",
"0x53815b9b9EB106c8EE98e0cB32085f37cEE9172B",
"0xA06436e0C4107DF69D73BC03f8AE706Bfe7E231c",
"0xAd02CfF24091D2E39026a06E4e7200a9C0183C5C",
"0x3815A4712a6A3B79F1024A45Eb2e855deA17533C",
"0xE382F258E8B2ec82E4dc419919eA9aecff10Ee19",
"0xe6bD7405B5bE9366771e23A5DfCDC8bc2E36d95b",
"0x93Dd2793890351e370a627259514Bf618570E0De",
"0xBB038679F3611062C4C4c8a376De3c586E175027",
"0x478C7BF0Da55d90FBCC0bcfd760210E409a5eBB3",
"0x9C11e1818CDE43ABe1EE09B8E47Aa5496669DF5E",
"0x03d75553D9329296a87E4b5A31534c6b4f29BDf5",
"0xF9Fd4d6e0432D74989EeB7FE9CB738097D089B68",
"0x3EdC6fC51E3fb43857e4a7a7755eb4B61c477077",
"0x19fA0Ca66209ADc102D29ae4256a5694DDCCDfAe",
"0x92bCd2E904eacD0A6ed86D3735056190E176107B",
"0x3575137a0eeAdDcFEeabBF1Fa0dEeeD3DC938255",
"0xe69fA3E027f765B5a8e6774bb90256c904A5a598",
"0xC8D3ddeF1Fc8240739D60965a102E3F7bf8BAE30",
"0x16585F1D95B689b98184be69C5dAC98df066E07F",
"0x64709906aa8D2F2404AFeD46827015F5D6DDDaaF",
"0x8aB4e6A5DB48a154B8B718c416113dc73193142e",
"0x32F5Bc8b89a183cB82171d5DBabABaeF1b0FA0e9",
"0x4E1A003A728560075Fea341394A9A6521A414C31",
"0xCe14da8a2D0687fE280481Fc72eEf623Fbec7B16",
"0xBcfB9fEea3F07D90C18A9bEfb94CF7b936B544c1",
"0x14E8FEe3417B8D451a8EDC3F0BFD47891C0Ea915",
"0x3e8f8b5f04C07251aab6175F3F9ED15fC1c093ed",
"0x355fB57DAb96A14ef608eF135f9564bAF7D46b24",
"0x91124b4446e99CaecC01E12c7c261d088571fE08",
"0x3e85f8b5329813A8c5e44689e4Ef1A8Fd194e6B6",
"0x6Fbf47662f2337761D91c3b8bfD5459f7CDBC2D8",
"0x1991aD777464658edc072e5f31574Cdc7DdcF447",
"0xf2e4a05cBae83fb3173BECEe7a31686e8A6ae3Ce",
"0x269b7Fb9F7Be8945E6d0fD5c132E86c79ab55D2B",
"0x9583BfD7ae26A47771deE09A5C9600d293139611",
"0x82f9D16811f00a0affB6fD0a61bF80BB5c4Af786",
"0x691AbBe6d8aC6a284Eb6bD08240e3AFF0F25d016",
"0x91605a43C57a830607770654c4f91a5F0CDCaB9d",
"0x3EE14c0eAf63B222ba5814a4E257Ce1e578b7496",
"0x56fE9301A712f58E0A49e3a33f7B81707cB3c761",
"0xa95D430Aef53F62778B285A0ebEb3Cc395b3AeB3",
"0x11edbC9FdEFA432e71bF0357eB000C5e1AaBc8Be",
"0x8b1eD4a9A8040819E6E755cA3AeFB27aec01bb5c",
"0xa7f3555b1701CcBaF8d11FEfe67139e6278b6c06",
"0x9F5B3C6f915dDfB7095957e3B00f2A0BFb114eeB",
"0x58db0Ca437Cf8BF1e63d23A41F88C618e420d12b",
"0xdE424E19594cc0416b59F532a6d3ff33ee844AfB",
"0x206121E0F05c5a848A5833f9Ae388584d31c0D55",
"0x789844d779282cBA3910491881f8D7d3b59E9d7e",
"0xC652af2F515b671c4a89e60C97360e71ae535978",
"0x24Fd0fcBCD5d09A900C3C18501D1688cCB5fbF14",
"0x825acd62C9F7631939681d00802E7d58fec19F83",
"0xD2268a4C5Ae6322ec62F79DB917cd8C997085A0D",
"0x173A5aaa6a6a960C6455552cD66E1567C8bcd45A",
"0x12c00D6A8ccAE39fB35401e38c37AC9Fc0ceBb97",
"0xd72a48905fac39cb051b4faf76a981301cc95c43",
"0x12d840F959BC4eA9a1F49f31681fF448df6e719F",
"0xbd6F97D8Bee317D46067B7f377d68f71d23C93b1",
"0x54C4dF8B0a3b5225b75EBdADEb6d6bdC74e80645",
"0x872771bCb7ED4D9E98Ab425Cd4d66f1B9c6BE693",
"0x4BFeAcB1cA814499c4F2B0088511c5a4EC57D98c",
"0x728fB867950e20D40C0a38681168AA9d57fC7C20",
"0x423591719a6167B589aB7580B810fDF49d765b5d",
"0xcABD1746815c6B4F3D0Ed3a7f0A22eaB2aECa9e8",
"0x7281197Fb31f3229ec8D145aD9772a5a4365F297",
"0x0fD7e54a44146A4E42F325444C488F721E1BEc47",
"0x83f452e60C8C369eA714F79e9Ed64c68129c389b",
"0x4A36DECFCeC71AF85Df99fDbE48Bc914F97fF79F"]

export default whitelistAddresses;